<script setup lang="ts">
// This field is used to select a date. It can be used with a custom date picker, or with the browser's native date picker.
// The browser's native input has some limitations, such as not being able to specify the date format.

const props = withDefaults(
  defineProps<{
    name: string
    label?: string
    icon?: string
    placeholder?: string
    isSubmitting?: boolean
    cols?: number
    type?: string
    pickerType?: 'input' | 'datepicker'
    multipleDates?: boolean
  }>(),
  {
    label: undefined,
    icon: undefined,
    placeholder: undefined,
    isSubmitting: false,
    cols: 12,
    type: undefined,
    pickerType: 'input',
    multipleDates: false,
  },
)

// Convert ISO date string to a date string the input date field can handle
const formatDateString = (date?: Date[] | Date | string | string[] | undefined | null): string[] | string | undefined => {
  if (Array.isArray(date)) {
    return date.map(formatDateString) as string[] | undefined
  }
  if (!date) return undefined
  if (typeof date === 'string') date = new Date(date)
  return date.toISOString().split('T')[0]
}

const { value, setValue, errorMessage } = useField<Date | string | Date[] | string[] | null >(props.name)

</script>

<template>
  <div :class="`col-span-${cols}`">
    <BaseInput
      v-if="pickerType === 'input'"
      type="date"
      :icon="icon"
      :label="label"
      :placeholder="placeholder"
      :error="errorMessage"
      :disabled="isSubmitting"
      :model-value="formatDateString(value)"
      @update:model-value="setValue($event)"
    />

    <div v-if="pickerType === 'datepicker'">
      <label
        class="nui-label w-full pb-1 text-[0.825rem]"
      >
        {{ props.label }}
      </label>
      <Datepicker
        expanded
        class="!mx-0 mt-1"
        :model-value="formatDateString(value)"
        @update:model-value="setValue($event)"
      />
    </div>

    <ErrorMessage
      v-if="pickerType === 'datepicker'"
      :name="name"
      class="mt-1 text-[10px] text-red-500"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "": ""
  },
  "nl": {
    "": ""
  }
}
</i18n>
