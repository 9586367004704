export interface Form extends ApiEntity {
  organizationId?: id
  title: string
  description?: string
  identifier: string
}

// enum :question_type, [:text, :multiple_choice, :date, :number, :checkbox, :email, :url, :phone]
export enum QuestionType {
  Text = 'text',
  MultipleChoice = 'multiple_choice',
  Date = 'date',
  Number = 'number',
  Checkbox = 'checkbox',
  Email = 'email',
  Url = 'url',
  Phone = 'phone',
}

export interface FormQuestion extends ApiEntity {
  formId: id
  position: number
  text: string
  longAnswer: boolean
  required: boolean
  questionType: QuestionType
  options?: string[] // multiple choice options
}

export function fetchForm(id: id) {
  return useJsonApiFetch<Form & { formQuestions: FormQuestion[] }>(`/api/v1/forms/${id}?include=form_questions`)
}
