// TODO: remove import, shouldn't be necessary
import type { FormQuestion, Form } from './forms'

export interface FormSubmission extends ApiEntity {
  formId: id
  submitted: boolean
  userId?: id
}

export interface FormQuestionResponse extends ApiEntity {
  formSubmissionId: id
  formQuestionId: id
  value: id
}

export function fetchFormSubmissionByToken(token: string) {
  return useJsonApiFetch<FormSubmission &
    { form: Form & { formQuestions: FormQuestion[] }
      formQuestions: FormQuestion[]
      formQuestionResponses: FormQuestionResponse[]
    }>(
    `/api/v1/form_submissions/${token}?include=form,form_questions,form_question_responses`,
    )
}

export async function submitFormSubmission(token: string, payload: Record<string, any>) {
  return await $api(`/api/v1/form_submissions/${token}/submit`, {
    method: 'POST',
    body: {
      data: {
        type: 'form_submission',
        id: token,
        attributes: payload,
      },
    },
  })
}

export async function submitPublicForm(formId: id, payload: Record<string, any>) {
  return await $api(`/api/v1/form_submissions/${formId}/submit_public`, {
    method: 'POST',
    body: {
      data: {
        type: 'form_submission',
        attributes: payload,
      },
    },
  })
}
